<template>
    <Calendar />
</template>
<script>
import Calendar from "@/components/auth/calendar/Calendar.vue";
export default {
    components: {
        Calendar,
    }
}
</script>